<template>
    <v-card
        v-if="isDataLoading || dataError"
        class="card-shadow border-radius-xl"
    >
        <div class="card-header-padding">
            <h5 v-if="header" class="font-weight-bold text-h5 text-typo mb-0">
                {{ header }}
            </h5>
            <v-progress-circular
                v-if="isDataLoading && !dataError"
                class="d-block mx-auto my-4"
                indeterminate
                :size="48"
                :width="7"
            ></v-progress-circular>
            <p v-else class="text-sm text-red font-weight-light mb-0">
                {{ $t('datatable.loadingError') }}
            </p>
        </div>
    </v-card>

    <v-card v-else class="card-shadow border-radius-xl">
        <div v-if="header || subheader" class="card-header-padding">
            <h5 v-if="header" class="font-weight-bold text-h5 text-typo mb-0">
                {{ header }}
            </h5>
            <p
                v-if="subheader"
                class="text-sm text-body font-weight-light mb-0"
            >
                {{ subheader }}
            </p>
        </div>

        <v-card-text class="px-0 py-0">
            <v-data-table
                :headers="headers"
                :items="data"
                :search="search"
                class="table"
                :page.sync="page"
                hide-default-footer
                @page-count="pageCount = $event"
                :items-per-page="itemsPerPage"
                mobile-breakpoint="0"
                :no-data-text="$t('datatable.noDataAvailable')"
            >
                <template v-slot:top>
                    <v-toolbar flat height="80">
                        <v-row>
                            <v-col cols="12" md="5">
                                <v-text-field
                                    hide-details
                                    class="input-style font-size-input text-light-input placeholder-light input-icon"
                                    dense
                                    flat
                                    filled
                                    solo
                                    height="43"
                                    v-model="search"
                                    :placeholder="$t('form.search')"
                                >
                                    <template slot="prepend-inner">
                                        <v-icon
                                            color="#adb5bd"
                                            size="16"
                                            class="material-icons-round mt-n2"
                                            >search</v-icon
                                        >
                                    </template>
                                </v-text-field>
                            </v-col>
                        </v-row>

                        <v-spacer></v-spacer>

                        <v-dialog v-model="dialog" max-width="500px">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    v-bind="attrs"
                                    v-on="on"
                                    elevation="0"
                                    :ripple="false"
                                    height="43"
                                    class="font-weight-normal text-capitalize btn-primary bg-gradient-primary py-3 px-6 ms-3"
                                    >{{ $t('datatable.action.addNew') }}
                                </v-btn>
                            </template>
                            <v-form v-model="form" @submit.prevent="onSubmit">
                                <v-card class="card-shadow border-radius-xl">
                                    <div
                                        class="card-header-padding card-border-bottom"
                                    >
                                        <span
                                            class="font-weight-bold text-h5 text-typo mb-0"
                                            >{{ formTitle }}</span
                                        >
                                    </div>
                                    <v-card-text class="card-padding">
                                        <v-container class="px-0">
                                            <v-row>
                                                <v-col
                                                    v-for="(
                                                        field, key
                                                    ) in editedItemFields"
                                                    cols="6"
                                                    :key="key"
                                                >
                                                    <v-text-field
                                                        v-if="
                                                            field.type ===
                                                                'text' ||
                                                            !field.type
                                                        "
                                                        v-model="
                                                            editedItem[key]
                                                        "
                                                        :label="field.name"
                                                        :rules="[
                                                            rules.required,
                                                        ]"
                                                    >
                                                    </v-text-field>
                                                    <v-select
                                                        v-if="
                                                            field.type ===
                                                            'select'
                                                        "
                                                        v-model="
                                                            editedItem[key]
                                                        "
                                                        :no-data-text="
                                                            $t(
                                                                'form.select.noDataAvailable'
                                                            )
                                                        "
                                                        :items="field.options"
                                                        :label="field.name"
                                                        :rules="[
                                                            rules.required,
                                                        ]"
                                                    ></v-select>
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-card-text>

                                    <v-card-actions
                                        class="card-padding d-flex justify-end"
                                    >
                                        <v-btn
                                            @click="close"
                                            elevation="0"
                                            :ripple="false"
                                            height="43"
                                            class="font-weight-normal text-capitalize btn-ls btn-outline-secondary bg-transparent py-3 px-6"
                                            >{{
                                                $t('form.button.cancel')
                                            }}</v-btn
                                        >

                                        <v-btn
                                            @click="save"
                                            :disabled="!form"
                                            elevation="0"
                                            :ripple="false"
                                            height="43"
                                            class="font-weight-normal text-capitalize btn-ls btn-primary bg-gradient-primary py-3 px-6"
                                            >{{
                                                $t('form.button.confirm')
                                            }}</v-btn
                                        >
                                    </v-card-actions>
                                </v-card>
                            </v-form>
                        </v-dialog>

                        <v-dialog v-model="dialogDelete" max-width="500px">
                            <v-card
                                class="card-shadow card-padding border-radius-xl"
                            >
                                <v-card-title
                                    class="pt-0 text-h5 text-typo justify-center"
                                    >{{
                                        $t('datatable.formHeader.delete')
                                    }}</v-card-title
                                >
                                <v-card-actions class="pb-0">
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        @click="closeDelete"
                                        elevation="0"
                                        :ripple="false"
                                        height="43"
                                        class="font-weight-normal text-capitalize btn-ls bg-transparent btn-outline-secondary py-3 px-6"
                                        >{{ $t('form.button.cancel') }}</v-btn
                                    >

                                    <v-btn
                                        @click="deleteItemConfirm()"
                                        elevation="0"
                                        :ripple="false"
                                        height="43"
                                        class="font-weight-normal text-capitalize btn-ls btn-primary bg-gradient-primary py-3 px-6"
                                        >{{ $t('form.button.confirm') }}</v-btn
                                    >
                                    <v-spacer></v-spacer>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-toolbar>
                </template>

                <template v-slot:item.actions="{ item }">
                    <v-btn
                        @click="editItem(item)"
                        icon
                        elevation="0"
                        :ripple="false"
                        height="28"
                        min-width="36"
                        width="36"
                        class="btn-ls me-2 my-2 rounded-sm"
                        color="#67748e"
                    >
                        <v-icon size="14" class="material-icons-round"
                            >construction</v-icon
                        >
                    </v-btn>

                    <v-btn
                        @click="deleteItem(item)"
                        icon
                        elevation="0"
                        :ripple="false"
                        height="28"
                        min-width="36"
                        width="36"
                        class="btn-ls me-2 my-2 rounded-sm"
                        color="#67748e"
                    >
                        <v-icon size="14" class="material-icons-round"
                            >close</v-icon
                        >
                    </v-btn>
                </template>

                <!-- Slots for custom data display -->
                <template v-slot:item="{ item }">
                    <tr>
                        <td
                            v-for="(
                                columnValue, key
                            ) in filterPropertiesIncludedInTableHeaders(item)"
                            :key="key"
                        >
                            <slot :name="key" :item="item">
                                {{ columnValue }}
                            </slot>
                        </td>
                        <td v-if="headerValues.includes('actions')">
                            <v-btn
                                @click="editItem(item)"
                                icon
                                elevation="0"
                                :ripple="false"
                                height="28"
                                min-width="36"
                                width="36"
                                class="btn-ls me-2 my-2 rounded-sm"
                                color="#67748e"
                            >
                                <v-icon size="14" class="material-icons-round"
                                    >construction</v-icon
                                >
                            </v-btn>

                            <v-btn
                                @click="deleteItem(item)"
                                icon
                                elevation="0"
                                :ripple="false"
                                height="28"
                                min-width="36"
                                width="36"
                                class="btn-ls me-2 my-2 rounded-sm"
                                color="#67748e"
                            >
                                <v-icon size="14" class="material-icons-round"
                                    >close</v-icon
                                >
                            </v-btn>
                        </td>
                    </tr>
                </template>
                <!--  -->

                <template v-slot:footer>
                    <slot name="footer"></slot>
                </template>
            </v-data-table>
        </v-card-text>
        <v-card-actions class="card-padding">
            <v-row>
                <v-col cols="12" sm="6" lg="3" class="d-flex align-center">
                    <span class="text-body me-3 text-sm">{{
                        $t('datatable.pagination.rowsPerPage')
                    }}</span>
                    <v-text-field
                        hide-details
                        type="number"
                        outlined
                        min="-1"
                        max="15"
                        background-color="rgba(255,255,255,.9)"
                        color="rgba(0,0,0,.6)"
                        light
                        :value="itemsPerPage"
                        @input="itemsPerPage = parseInt($event, 10)"
                        class="font-size-input placeholder-lighter text-color-light input-alternative input-focused-alternative input-icon"
                    >
                    </v-text-field>
                </v-col>
                <v-col cols="12" sm="6" class="ml-auto d-flex justify-end">
                    <v-pagination
                        prev-icon="fa fa-angle-left"
                        next-icon="fa fa-angle-right"
                        class="pagination"
                        color="#D81B60"
                        v-model="page"
                        :length="pageCount"
                        circle
                    ></v-pagination>
                </v-col>
            </v-row>
        </v-card-actions>
    </v-card>
</template>
<script>
export default {
    name: 'datatable-full',
    props: {
        data: {
            type: Array,
            required: false,
        },
        tableSettings: {
            type: Object,
            required: true,
        },
        isDataLoading: {
            type: Boolean,
            required: true,
        },
        dataError: {
            type: Boolean,
            required: true,
        },
        header: {
            type: String,
            required: false,
        },
        subheader: {
            type: String,
            required: false,
        },
    },
    data() {
        return {
            rules: {
                required: (value) => !!value || this.$t('form.rule.required'),
            },
            form: false,
            page: 1,
            pageCount: 0,
            itemsPerPage: 10,
            dialog: false,
            dialogDelete: false,
            search: '',
            editedIndex: -1,
            editedItem: this.tableSettings.editedItem,
            defaultItem: this.tableSettings.editedItem,
            headers: this.tableSettings.headers,
            editedItemFields: this.tableSettings.editedItemFields,
        };
    },
    computed: {
        formTitle() {
            return this.editedIndex === -1
                ? this.$t('datatable.formHeader.new')
                : this.$t('datatable.formHeader.edit');
        },
        pages() {
            return this.pagination.rowsPerPage
                ? Math.ceil(this.items.length / this.pagination.rowsPerPage)
                : 0;
        },
        tableDataFiltered() {
            if (this.data) {
                return this.data.map((item) => {
                    return Object.keys(item)
                        .filter((key) => this.headerValues.includes(key))
                        .reduce((newObject, key) => {
                            newObject[key] = item[key];
                            return newObject;
                        }, {});
                });
            }
            return null;
        },
        headerValues() {
            return this.headers.map((header) => {
                return header.value;
            });
        },
    },
    methods: {
        filterPropertiesIncludedInTableHeaders(item) {
            return Object.keys(item)
                .filter((key) => this.headerValues.includes(key))
                .reduce((newObject, key) => {
                    newObject[key] = item[key];
                    return newObject;
                }, {});
        },
        editItem(item) {
            this.editedIndex = this.data.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.dialog = true;
        },

        deleteItem(item) {
            this.editedIndex = this.data.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.dialogDelete = true;
        },

        deleteItemConfirm() {
            this.closeDelete();
            this.$emit('deleteItem', this.editedItem.id);
        },

        close() {
            this.dialog = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
        },

        closeDelete() {
            this.dialogDelete = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
        },

        save() {
            if (!this.form) return;
            if (this.editedIndex > -1) {
                this.$emit('editItem', this.editedItem);
            } else {
                this.$emit('addItem', this.editedItem);
            }
            this.close();
        },
    },
    watch: {
        dialog(val) {
            val || this.close();
        },
        dialogDelete(val) {
            val || this.closeDelete();
        },
    },
};
</script>

<style lang="scss" scoped>
.theme--light.v-btn.v-btn--disabled {
    color: #fff4 !important;
}
</style>
